/**
 * Component for Return / Shipping-Labels settings page in the Dashboard
 * that allows configuration of our Shippo, ShipStation and EasyPost
 * integrations for the user.
 *
 * @component
 */

import React from "react";
import axios from "axios";
import { Button, Modal, Row, Col, Select, Divider, Alert, message, Checkbox, Tooltip } from "antd";
import { SubscriptionContext } from "../../../../../contexts/SubscriptionContext";
import EasyPost from "./ReturnLabelEasypost";
import Shippo from "./ReturnLabelShippo";
import ShipStation from "./ReturnLabelShipstation";
import ReturnLabelDimensions from "./ReturnLabelDimensions";
import ReturnLabelWeight from "./ReturnLabelWeight";
import ReturnLabelCustoms from "./ReturnLabelCustoms";
import { trackFSEvent } from "../../../../../helpers/fullstory";
import UPS from "./ReturnLabelUPS";
import AusPost from "./ReturnLabelAusPost";

const { Option } = Select;

class ReturnLabel extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      labelService: undefined,
      labelCarrier: undefined,
      labelCarrierName: undefined,
      labelServicelevel: undefined,
      labelServicelevelName: undefined,
      labelAccountNumber:undefined,
      labelApiKey: undefined,
      labelPassword:undefined,
      labelDeliveryService:undefined,
      labelApiSecret: undefined,
      labelParcelHeight: undefined,
      labelParcelWidth: undefined,
      labelParcelLength: undefined,
      labelParcelDefaultWeigth: undefined,
      labelParcelWeightUnit: undefined,
      labelParcelDimensionsUnit: undefined,
      carrierListShipStation: undefined,
      ratesListShipStation: undefined,
      carrierListEasyPost: undefined,
      customsNumberOrExemption: undefined,
      loading: false,
      shippoScanBasedLabels: false,
      autoTracking: false,
      easypostEnableReturnLabels: true,
      labelEnableQRcode: false,
      errorMessage: null,
      enableSameLabelCurrency: false,
      buyerAddressAdded: false,
      domesticService: null,
      internationalService: null,
    };
  }

  async componentDidMount() {
    this.setState({
      ...this.props.state,
    });
    if (this.props.state.labelApiKey) {
      this.inputFieldValidation(this.props.state.labelApiKey);
    }
    try {
      const response = await axios.get("/api/connections");
      const couriers = ["ups"]
      response.data.connectionSettings.forEach((connection) => {
        if (couriers?.includes(connection.identifier) && connection.status === "connected") {
          this.setState({
            [connection.identifier]: "connected",
          })
        }
      })
    }catch (err) {
      message.error("Error retrieving connections. Try again.", 5);
    }
  }

  handleCancel() {
    this.props.showConfigureModal(false);
  }

  handlePayload = () => {
    let labelkey;
    if (this.state.labelApiKey) {
      labelkey = this.state.labelApiKey.trim();
    } else {
      labelkey = this.state.labelApiKey
    }
    let payload = {
      labelService: this.state.labelService,
      labelCarrier: this.state.labelCarrier,
      labelCarrierName: this.state.labelCarrierName,
      labelServicelevel: this.state.labelServicelevel ?? null,
      labelServicelevelName: this.state.labelServicelevelName ?? null,
      labelApiKey: labelkey,
      labelParcelHeight: this.state.labelParcelHeight,
      labelParcelWidth: this.state.labelParcelWidth,
      labelParcelLength: this.state.labelParcelLength,
      labelParcelWeightUnit: this.state.labelParcelWeightUnit,
      labelParcelDimensionsUnit: this.state.labelParcelDimensionsUnit,
      customsNumberOrExemption: this.state.customsNumberOrExemption,
      labelParcelDefaultWeigth: this.state.labelParcelDefaultWeigth,
      domesticService: this.state.domesticService,
      internationalService: this.state.internationalService,
    };
    if (this.state.labelService === "easypost") {
      return {
        ...payload,
        carrierListEasyPost: this.state.carrierListEasyPost,
        autoTracking: this.state.autoTracking,
        labelSelectCheapestRate: this.state.labelSelectCheapestRate,
        enableBuyerAddress: this.state.enableBuyerAddress,
        easypostEnableReturnLabels: this.state.easypostEnableReturnLabels,
        labelEnableQRcode: this.state.labelEnableQRcode,
        enableSameLabelCurrency: this.state.enableSameLabelCurrency,
      };
    }
    if (this.state.labelService === "shippo") {
      return {
        ...payload,
        shippoScanBasedLabels: this.state.shippoScanBasedLabels,
        labelSelectCheapestRate: this.state.labelSelectCheapestRate,
        autoTracking: this.state.autoTracking,
      };
    }
    if (this.state.labelService === "shipstation") {
      const secretkey = this.state.labelApiSecret.trim();
      return {
        ...payload,
        labelApiSecret: secretkey,
        ratesListShipStation: this.state.ratesListShipStation,
      };
    }
    if (this.state.labelService === "ups") {
      return {
        ...payload,
        labelCarrierName: null,
        labelServicelevel: null,
      }
    }
    if (this.state.labelService === "auspost") {
      return {
        ...payload,
        labelAccountNumber: this.state.labelAccountNumber,
        labelPassword: this.state.labelPassword,
        labelDeliveryService: this.state.labelDeliveryService,
        labelSelectCheapestRate: this.state.labelSelectCheapestRate,
      };
    }
  };

  inputFieldValidation = (value) => {
    const regEx = /API|:|;|test|sandbox|api/gi;
    const result = value.match(regEx);
    if (result === null) {
      this.setState({
        errorMessage: true
      })
    } else {
      this.setState({
        errorMessage: false
      })
    }
  }

  handleSubmit = async () => {
    // trackFSEvent("Configure prepaid return-labels", {
    //   feature: "Return Settings",
    //   shippingPlatform: this.state.labelService,
    //   enableAutoTracking: this.state.autoTracking,
    //   enableScanBasedLabels: this.state.shippoScanBasedLabels || false,
    //   enableAutomaticChooseTheCheapestRate: this.state.labelSelectCheapestRate || false,
    //   carrierType: this.state.labelCarrier,
    //   serviceType: this.state.labelServicelevel,
    // });
    const values = this.handlePayload();
    if (!this.state.labelService === 'ups' && !this.state.labelApiKey) {
      return message.error("API key is missing.", 5);
    } else if (this.state.labelService === 'ups' && !this.state.labelApiKey) {
      return message.error("Billing Number is missing.", 5);
    }
    if(this.state.labelService === 'shippo' && 
        !this.state.labelSelectCheapestRate && !this.state.labelServicelevel) {
      return message.error("Service is missing.", 5);
    }
    if (this.state.labelService === "auspost") {
      if (!this.state.labelAccountNumber) {
        return message.error("Account number is missing.", 5);
      }
      if (!this.state.labelPassword) {
        return message.error("Password is missing.", 5);
      }
      if (!this.state.labelDeliveryService && !this.state.labelSelectCheapestRate) {
        return message.error("Delivery service is missing.", 5);
      }
    }
    else if (this.state.errorMessage === false) {
      return message.error("Invalid Key.", 5);
    }
    if (this.state.enableBuyerAddress && !this.state.buyerAddressAdded && !this.props.state.buyerEmail) {
      return message.error(
        "Please add buyer address and try again.",
        5
      );
    }
    try {
      const response = await axios.post("/api/returnSettings/update", values);
      if (response.data.status === "error") {
        return message.error(
          "Error updating your return settings. Please try again.",
          5
        );
      }

      this.setState({
        ...response.data,
      });
      this.props.setParentState({...response.data});
      return message.success("Return settings successfully updated.", 5);
    } catch (err) {
      return message.error(
        "Error updating your return settings. Please try again.",
        5
      );
    }
  };

  saveSettings = async () => {
    const values = this.handlePayload();
    try {
      const response = await axios.post("/api/returnSettings/update", values);
      this.setState({
        ...response.data
      });
      this.props.setParentState({...response.data});
    } catch (err) { }
  };

  handleServiceChange = async (value) => {
    this.setState({
      labelService: value,
      labelSelectCheapestRate: false,
      labelCarrier: undefined,
      labelCarrierName: undefined,
      labelServicelevel: undefined,
      labelServicelevelName: undefined,
      labelSelectCheapestRate: false,
      labelApiKey: undefined,
      domesticService: null,
      internationalService: null,
    });
  };

  onChangeAttachment = (e) => {
    this.setState({
      enableSameLabelCurrency: e.target.checked,
    });
  }

  buyerAddressStatus = async (value) => {
    this.setState({
      buyerAddressAdded: value,
    });
  };
  render() {

    const handleChange = async (state, value) => {
      if (state === 'labelApiKey') {
        const trimValue = value.trim();
        if (!value) {
          this.setState({
            domesticService: null,
            internationalService: null,
          })
        }
        this.inputFieldValidation(trimValue);
        this.setState({
          [state]: value,
        });
      }
      else {
        this.setState({
          [state]: value,
        });
      }
    };


    return (
      <React.Fragment>
        <Modal
          width="50%"
          title="Set up your prepaid return-labels"
          visible={true}
          onOk={this.handleSubmit.bind(this)}
          onCancel={this.handleCancel.bind(this)}
          okText="Save"
          cancelText="Close"
          footer={[
            <Button key="back" onClick={this.handleCancel.bind(this)}>
              Close
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={this.handleSubmit.bind(this)}
            >
              Save
            </Button>,
          ]}
        >
          <Row type="flex" justify="space-around" align="middle">
            <Col span={16}>
              <Alert
                message="How to set up automated return labels?"
                description={
                  <span>
                    We cover the setup of automated return labels{" "}
                    <a
                      href="https://support.apphub.com/en/articles/6338243-how-to-set-up-automated-return-labels"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      here in our Help Docs
                    </a>
                  </span>
                }
                type="info"
                showIcon
              />
            </Col>
          </Row>

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          <Row type="flex" justify="space-around" align="middle">
            <Col span={10}>
              <h4>Shipping Platform</h4>
              <p>
                Click on the link at the top to see an overview of supported
                Shipping Platforms and Carriers.
              </p>
            </Col>
            <Col span={10}>
              <Select
                style={{ width: 120 }}
                defaultValue={this.state.labelService}
                onSelect={this.handleServiceChange.bind(this)}
              >
                <Option value="shippers" disabled={true}>-- Shippers --</Option>
                <Option value="shippo">Shippo</Option>
                <Option value="shipstation">ShipStation</Option>
                <Option value="easypost">EasyPost</Option>
                <Option value="carriers" disabled={true}>-- Carriers --</Option>
                {
                  this.state.upsEnabled ? 
                    <Option value="ups">
                      UPS
                    </Option>
                    :
                    <Option 
                      value="ups" 
                      disabled={true}
                      style={{padding: "unset"}} 
                    >
                      <Tooltip placement="bottom" title={
                        <div>
                          Integrate UPS from your account to use this service. <a href={`/dashboard/connect`}>click here</a>
                        </div>
                      }
                      >
                        <div style={{width: "100%", padding: "5px 12px"}}>UPS</div>
                      </Tooltip>
                    </Option>
                }
                <Option value="auspost">Australia Post</Option>
              </Select>
              {(!this.state.labelApiKey && this.state.labelService === "easypost") &&
                <React.Fragment>
                  <br />
                  <br />
                  <span>
                    If you don't have an EasyPost account click{" "}
                    <a href="http://www.easypost.com/signup?utm_source=Rich-Returns" target="_blank">
                      here
                    </a>
                  </span>
                </ React.Fragment>
              }
            </Col>
          </Row>
          {this.state.labelService === "easypost" ? (
            <EasyPost
              state={this.state}
              labelServicelevel={this.state.labelServicelevel}
              labelService={this.state.labelService}
              autoTracking={this.state.autoTracking}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              labelCarrier={this.state.labelCarrier}
              labelApiKey={this.state.labelApiKey}
              autoTrackingWebhookSecret={this.state.autoTrackingWebhookSecret}
              saveSettings={this.saveSettings}
              labelEnableQRcode={this.state.labelEnableQRcode}
              carrierListEasyPost={this.state.carrierListEasyPost}
              easypostEnableReturnLabels={this.state.easypostEnableReturnLabels}
              buyerAddressStatus={this.buyerAddressStatus}
              enableBuyerAddress={this.state.enableBuyerAddress}
              editOrUpdate={(this.state.enableBuyerAddress && !this.state.buyerAddressAdded && !this.state.buyerEmail) ? true : false}
            />
          ) : null}

          {this.state.labelService === "shippo" ? (
            <Shippo
              labelServicelevel={this.state.labelServicelevel}
              labelServicelevelName={this.state.labelServicelevelName}
              labelService={this.state.labelService}
              autoTracking={this.state.autoTracking}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              labelCarrier={this.state.labelCarrier}
              labelCarrierName={this.state.labelCarrierName}
              labelApiKey={this.state.labelApiKey}
              autoTrackingWebhookSecret={this.state.autoTrackingWebhookSecret}
              shippoScanBasedLabels={this.state.shippoScanBasedLabels}
              saveSettings={this.saveSettings}
            />
          ) : null}
          {this.state.labelService === "shipstation" ? (
            <ShipStation
              labelServicelevel={this.state.labelServicelevel}
              labelService={this.state.labelService}
              labelApiSecret={this.state.labelApiSecret}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              labelCarrier={this.state.labelCarrier}
              labelApiKey={this.state.labelApiKey}
              ratesListShipStation={this.state.ratesListShipStation}
              carrierListShipStation={this.state.carrierListShipStation}
              saveSettings={this.saveSettings}
            />
          ) : null}
          {this.state.labelService === "ups" ? (
            <UPS
              domesticService={this.state.domesticService}
              internationalService={this.state.internationalService}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              labelApiKey={this.state.labelApiKey}
            />
          ) : null}
          {this.state.labelService === "auspost" ? (
            <AusPost
              labelAccountNumber={this.state.labelAccountNumber}
              labelApiKey={this.state.labelApiKey}
              labelPassword={this.state.labelPassword}
              labelDeliveryService={this.state.labelDeliveryService}
              labelSelectCheapestRate={this.state.labelSelectCheapestRate}
              handleChange={handleChange}
              inputFieldValidation={this.state.errorMessage}
              saveSettings={this.saveSettings}
            />
          ) : null}
          

          <ReturnLabelDimensions
            labelParcelDimensionsUnit={this.state.labelParcelDimensionsUnit}
            handleChange={handleChange}
            labelParcelLength={this.state.labelParcelLength}
            labelParcelWidth={this.state.labelParcelWidth}
            labelParcelHeight={this.state.labelParcelHeight}
          />

          <Row type="flex" justify="space-around" align="top">
            <Col span={22}>
              <Divider />
            </Col>
          </Row>
          {(this.state.labelService === "easypost" && this.context.featureLabelReturnCurrency) &&
            <React.Fragment>
              <Row type="flex" justify="space-around" align="middle">
                <Col span={10}>
                  <h4>Same currency as return</h4>
                  <p>If you want to use same currency in label as in return please enable the checkbox</p>
                </Col>
                <Col span={10}>
                  <Checkbox
                    checked={this.state.enableSameLabelCurrency}
                    onChange={(e) => this.onChangeAttachment(e)}
                  >
                    Enable
                  </Checkbox>
                </Col>
              </Row>
              <Row type="flex" justify="space-around" align="top">
                <Col span={22}>
                  <Divider />
                </Col>
              </Row>
            </React.Fragment>
          }

          <ReturnLabelWeight
            handleChange={handleChange}
            labelParcelWeightUnit={this.state.labelParcelWeightUnit}
            labelParcelDefaultWeigth={this.state.labelParcelDefaultWeigth}
          />

          {!this.context.featureCustomsForms ? null : (
            <ReturnLabelCustoms
              handleChange={handleChange}
              customsNumberOrExemption={this.state.customsNumberOrExemption}
            />
          )}
        </Modal>
      </React.Fragment>
    );
  }
}

export default ReturnLabel;
